import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql, Link, withPrefix } from "gatsby"
import { useLazyQuery } from "@apollo/react-hooks"
import { useMediaPredicate } from "react-media-hook"
import ReactMarkdown from "react-markdown/with-html"

import { FIND_PROPERTY } from "../../graphql/development-graphql"

import "../../graphql/fragments"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../components/cover-main/cover-main"
import Slider from "react-slick";
import { Section, Container } from "../../components/grid/grid"
import { ProjectsByLocation } from "../../components/utils/render-functions"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const WishForHomePage = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerResidence.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgPropertyFinder: file(relativePath: { eq: "residence-finder.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      featured: file(relativePath: { eq: "img_development-main.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        propertyType(id: "properti", idType: SLUG) {
          name
          description
          acfPropertyType {
            image {
              sourceUrl
            }
            banner {
              sourceUrl
            }
            sliderImageBanner {
              link
              image {
                sourceUrl
              }
            }
            sliderImageMobile {
              link
              image {
                sourceUrl
              }
            }
          }
          projects(first: 1000) {
            nodes {
              id
              projectTags {
                edges {
                  node {
                    name
                  }
                }
              }
              title
              slug
              excerpt
              locations {
                nodes {
                  name
                  slug
                }
              }
              propertyTypes {
                nodes {
                  name
                  slug
                }
              }
              featuredImage {
                sourceUrl
                caption
                mediaDetails {
                  sizes {
                    name
                    width
                    height
                    file
                    sourceUrl
                  }
                }
              }
            }
          }
        }
        facilities(first: 100) {
          nodes {
            name
            slug
            facilityId
            projects {
              nodes {
                propertyTypes {
                  nodes {
                    slug
                    name
                  }
                }
              }
            }
          }
        }

        locations(first: 100) {
          nodes {
            locationId
            slug
            name
          }
        }

        propertyTypes {
          nodes {
            name
            slug
            propertyTypeId
          }
        }
      }
    }
  `)

  const wordPress = staticData.wordPress.propertyType
  const resizeScreen = useMediaPredicate("(max-width: 992px)")

  const [findProperty, setFindProperty] = useState({
    location: null,
    propertyTypes: null,
    projects: null,
    checkBoxValues: [],
  })

  const [filterKey, setFilterKey] = useState("")
  const [filteredProjects, setFilteredProjects] = useState(
    wordPress.projects.nodes
  )

  const [
    getProperty,
    { data: properties, loading: loadingProperties },
  ] = useLazyQuery(FIND_PROPERTY)

  let featuredProperty
  wordPress.projects.nodes.map((item) => {
    if (
      item.projectTags.edges.length > 0 &&
      item.projectTags.edges.find((x) => x.node.name === "Featured Residence")
    ) {
      featuredProperty = item
    }
    return -1
  })

  const locations = []
  staticData.wordPress.locations.nodes.map((item) =>
    locations.push({ value: item.slug, label: item.name })
  )

  let propertyTypes = []
  staticData.wordPress.propertyTypes.nodes.map((item) =>
    propertyTypes.push({ value: item.slug, label: item.name })
  )

  let listProperties = []
  properties &&
    properties.projects.edges.map((item) => {
      listProperties.push({ label: item.node.title, value: item.node.slug })
    })
  const listPropertyTypes = staticData.wordPress.propertyTypes.nodes
  const listFacilities = staticData.wordPress.facilities.nodes
  const listLocations = staticData.wordPress.locations.nodes
  let listOfImages = []
  let listImagesBanner = wordPress.acfPropertyType.sliderImageBanner
  let listImagesMobile = wordPress.acfPropertyType.sliderImageMobile

  if(!resizeScreen){
    listImagesBanner &&
      listImagesBanner.map(item => {
        listOfImages.push({image:item.image.sourceUrl, url:item.link})
      })
  }else{
    listImagesMobile &&
      listImagesMobile.map(item => {
        listOfImages.push({image:item.image.sourceUrl, url:item.link})
      })
  }

  useEffect(() => {
    let facilities = []
    let locations = []
    let propertyTypeId =
      findProperty.propertyTypes &&
      listPropertyTypes.find((x) => x.slug === findProperty.propertyTypes.value)
        .propertyTypeId

    findProperty.location &&
      listLocations.map(
        (item) =>
          item.name === findProperty.location.label &&
          locations.push(item.locationId)
      )

    findProperty.checkBoxValues.length > 0 &&
      findProperty.checkBoxValues.map((item) => {
        listFacilities.map((facility) => {
          if (facility.slug === item) {
            facilities.push(facility.facilityId)
          }
        })
      })

    if (locations.length > 0 && propertyTypeId) {
      getProperty({
        variables: {
          locationId: locations.length > 0 ? locations : null,
          typeId: [propertyTypeId],
          first: 1000,
          facilityId: facilities.length > 0 ? facilities : null,
        },
      })
    }
  }, [findProperty])

  const setDataLayer = (projectTitles, city) => {
    const title = "Wish For Home"
    window.dataLayer.push({
      event: "Search",
      page_url: window.location.href,
      page_title: title,
      content_type: "home_listing",
      content_ids: projectTitles,
      city: city,
      region: city,
      country: "Indonesia",
      lease_start_date: "2021-01-01",
      lease_end_date: "2100-01-01",
    })
  }

  const handlerFilterProject = () => {
    let projects

    if (filterKey != "") {
      projects = wordPress?.projects?.nodes?.filter(
        (item) => item.title.toLowerCase().indexOf(filterKey.toLowerCase()) > -1
      )
    } else {
      projects = wordPress?.projects?.nodes
    }

    projects.forEach((item) => {
      const title = item.slug.replaceAll("-", "_")
      const city = item?.locations?.nodes[0].slug
      setDataLayer(title, city)
    })
    setFilteredProjects(projects)
  }

  useEffect(() => {
    let listOfImages = []
    let listImagesBanner = wordPress.acfPropertyType.sliderImageBanner
    let listImagesMobile = wordPress.acfPropertyType.sliderImageMobile

    if(!resizeScreen){
      listImagesBanner &&
        listImagesBanner.map(item => {
          listOfImages.push({image:item.image.sourceUrl, url:item.link})
        })
    }else{
      listImagesMobile &&
        listImagesMobile.map(item => {
          listOfImages.push({image:item.image.sourceUrl, url:item.link})
        })
    }

    document.querySelector(".animated").style.marginTop = "0px"
    document.querySelector(".sliderWfh").style.paddingTop = "0px"
    document.querySelector(".sliderWfh").style.paddingBottom = "0px"
  }, [])

  const settings = {
    autoplay: listOfImages.length > 1 ? true : false,
    autoplaySpeed: listOfImages.length > 1 ? 4000 : 0,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <Layout location={location} currentLocation={location.pathname}>
      <SEO title="Wish For Home" />
      <Helmet>
      <script src={withPrefix("slider.js")} type="text/javascript" />
        <body className="bd-page" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Section className="sliderWfh overflow-x-hidden" style={{padding:"0px"}}>
        <Slider {...settings}>
          {listOfImages.length !== null &&(
            listOfImages.map(item => {
              return(
                <div>
                  <img src={item.image} style={{width:"100%"}} onClick={()=> window.open(item.url, "_blank")}/>
                </div>
              )
            })
          )}
        </Slider>
      </Section>
      <Section>
        <Container className="container-md">
          <h2>Wish For Home</h2>
          <ReactMarkdown key={wordPress?.description} source={wordPress?.description} escapeHtml={false} />
        </Container>
      </Section>
      <Section className="main-properti">
        <div
          className={`nav-tabs-wrapper d-flex justify-content-center`}
          style={{ borderBottom: "none" }}
        >
          <ul className={`nav nav-tabs`} role="tablist">
            <li className={`nav-item`}>
              <div className={`nav-link active`} role="tab">
                Properti
              </div>
            </li>
            <li className={`nav-item`}>
              <Link className={`nav-link`} role="tab" to="/wishforhome/kavling">
                Kavling
              </Link>
            </li>
            <li className={`nav-item`}>
              <Link className={`nav-link`} role="tab" to="/wishforhome/faq">
                FAQ
              </Link>
            </li>
          </ul>
        </div>
        <Container id="search-bar">
          <div className="input-group mb-3">
            <input
              type="text"
              id="search-properti"
              className="form-control"
              placeholder="Cari properti disini"
              value={filterKey}
              onChange={(e) => setFilterKey(e.target.value)}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => handlerFilterProject()}
              >
                <i className="far fa-search"></i>
              </button>
            </div>
          </div>
        </Container>
        <Container>
          <ProjectsByLocation
            data={filteredProjects}
            location={location.pathname}
          />
        </Container>
      </Section>
    </Layout>
  )
}
export default WishForHomePage
